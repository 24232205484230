








import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import multiLanguageContentInput from "@/utils/multiLanguageContentInput";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "FAQ",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                faqItems: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Questions",
                    xOptions: {
                      content: {
                        defaultExpandDisabled: true,
                        itemLabel(item) {
                          return item.question && item.question[0] && item.question[0].content;
                        },
                        itemProperties: {
                          question: multiLanguageContentInput("Question", true, "text"),
                          answer: multiLanguageContentInput("Answer", true),
                        },
                        template: {
                          formOptions: {
                            content: {
                              colLength: 12,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call("setting", "update", undefined, {
                    data,
                  });
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "FAQ saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    const data = await coreApiClient.call("setting", "get");
    this.dataContainer.value = data;
  },
});
